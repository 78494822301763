<template>
    <div id="c8365" class="csc-default">
        <!-- Plugin inserted: [begin] -->
        <div class="tx_sbchalets">
            <form data-parsley-validate="" class="enquiryForm" id="enquiryModalForm" action="resorts/switzerland/zermatt/chalet-shalimar/?tx_sbchalets_pi1%5Bcontroller%5D=Booking" method="post" novalidate="">
                <div> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@extension]" value="SbChalets"> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@vendor]" value="SkiBoutique"> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@controller]" value="Booking"> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@action]" value="enquiry"> <input type="hidden" name="tx_sbchalets_pi1[__referrer][arguments]" value="YTowOnt98f9b108764c374708c2d4451230d654eaa11b20e"> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@request]" value="a:4:{s:10:&quot;@extension&quot;;s:9:&quot;SbChalets&quot;;s:11:&quot;@controller&quot;;s:7:&quot;Booking&quot;;s:7:&quot;@action&quot;;s:7:&quot;enquiry&quot;;s:7:&quot;@vendor&quot;;s:11:&quot;SkiBoutique&quot;;}93bd4bca95bdfb146eb198344f0ede89160a3ac2"> <input type="hidden" name="tx_sbchalets_pi1[__trustedProperties]" value="a:16:{s:4:&quot;name&quot;;i:1;s:9:&quot;last_name&quot;;i:1;s:5:&quot;email&quot;;i:1;s:9:&quot;telephone&quot;;i:1;s:12:&quot;arrival_date&quot;;i:1;s:6:&quot;adults&quot;;i:1;s:8:&quot;children&quot;;i:1;s:14:&quot;enquiry_source&quot;;i:1;s:10:&quot;preference&quot;;i:1;s:4:&quot;text&quot;;i:1;s:7:&quot;section&quot;;i:1;s:7:&quot;keyword&quot;;i:1;s:8:&quot;campaign&quot;;i:1;s:2:&quot;ad&quot;;i:1;s:19:&quot;viewing_chalet_name&quot;;i:1;s:11:&quot;sendenquiry&quot;;i:1;}c408165ee59208fa3dcd125007774343e8757f02"> </div>
                <span class="formLabel">ENQUIRE NOW</span>
                <div class="form-inline">
                    <div class="form-group"> <input v-model="first_name" placeholder="Name" :class="{'form-control': true, 'parsley-error': errors.indexOf('first_name') !== -1}" type="text" name="tx_sbchalets_pi1[name]" required="required" data-parsley-id="48"> </div>
                    <div class="form-group"> <input v-model="last_name" placeholder="Surname" :class="{'form-control': true, 'parsley-error': errors.indexOf('last_name') !== -1}" type="text" name="tx_sbchalets_pi1[last_name]" required="required" data-parsley-id="50"> </div>
                </div>
                <div class="form-group input-groups">
                    <div class="emailinput">
                        <input placeholder="Email" v-model="email" :class="{'form-control': true, 'parsley-error': errors.indexOf('email') !== -1}" type="text" name="tx_sbchalets_pi1[email]" required="required" data-parsley-id="52">
                    </div>
                </div>
                <div class="form-group"> <input v-model="phone" placeholder="Telephone" :class="{'form-control': true, 'parsley-error': errors.indexOf('phone') !== -1}" type="text" name="tx_sbchalets_pi1[telephone]" data-parsley-id="54"> </div>
                <div class="form-group">
                    <div class="arrivalinput"> <input v-model="arrival_date" placeholder="Arrival Date"  :class="{'form-control': true, 'arrival_date': true, 'parsley-error': errors.indexOf('arrival_date') !== -1}" type="date" required="required" data-parsley-id="56"> </div>
                </div>
                <div class="form-inline">
                    <div class="form-group">
                        <select required="" v-model="adults" :class="{'form-control': true, 'parsley-error': errors.indexOf('adults') !== -1}" name="tx_sbchalets_pi1[adults]" data-parsley-id="58">
                            <option value="">Adults</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <select required="" v-model="children" :class="{'form-control': true, 'parsley-error': errors.indexOf('children') !== -1}" name="tx_sbchalets_pi1[children]" data-parsley-id="60">
                            <option value="">Children</option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                        </select>
                    </div>
                </div>
                <div class="form-group selectPreference">
                    <select required="" v-model="flexible" :class="{'form-control': true, 'parsley-error': errors.indexOf('flexible') !== -1}" name="tx_sbchalets_pi1[preference]" data-parsley-id="64">
                        <option value="">Resort Preference</option>
                        <option value="0">I'm only interested in this resort</option>
                        <option value="1">I'm flexible on resort</option>
                    </select>
                </div>
                <div class="form-group">
                    <label>Tell us what you want?</label>
                    <textarea rows="4" cols="20" v-model="message" placeholder="Hint: We love detail! The more we have the better equipped we are to provide you with exactly what you want." :class="{'form-control': true, 'parsley-error': errors.indexOf('message') !== -1}" name="tx_sbchalets_pi1[text]" data-parsley-id="66"></textarea> </div>
                <input class="enquiryTabSection" type="hidden" name="tx_sbchalets_pi1[section]" value=""> <input type="hidden" name="tx_sbchalets_pi1[keyword]" value=""> <input type="hidden" name="tx_sbchalets_pi1[campaign]" value=""> <input type="hidden" name="tx_sbchalets_pi1[ad]" value=""> <input type="hidden" name="tx_sbchalets_pi1[viewing_chalet_name]" value="Chalet Shalimar">
                <div id="recaptcha2" class="g-recaptcha"></div>
                <button class="btn btn-default enquiryFormSubmit" type="submit" name="tx_sbchalets_pi1[sendenquiry]" value="Send" @click.prevent="submit">Send</button>
            </form>
        </div>
        <!-- Plugin inserted: [end] -->
    </div>
</template>

<script>
    import Chalet from "../../src/Chalet";
    import Enquiry from "../../src/Enquiry";
    import EnquiryMessage from "../../src/EnquiryMessage";

    export default {
        props: {
            chalet: {
                type: Chalet
            }
        },
        data: function ()
        {
            return {
                errors: [],
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                arrival_date: '',
                adults: '',
                children: '',
                flexible: '',
                message: '',
            }
        },
        methods: {
            submit: function ()
            {
                this.errors = [];

                let attributes = {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone: this.phone,
                    arrival_date: this.arrival_date,
                    adults: this.adults,
                    children: this.children,
                    flexible: this.flexible,
                    chalet_id: this.chalet.$attributes.uuid,
                    resort_id: this.chalet.$attributes.resort_id,
                    hash: Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 32)
                };


                Enquiry.$query().store(attributes).catch((e) =>
                {
                    this.errors = Object.keys(e.response.data.errors);
                })
                .then((enquiry) => {
                    if (this.errors.length == 0)
                    {
                        if (this.message)
                        {
                            EnquiryMessage.$query().store({
                                enquiry_id: enquiry.$attributes.id,
                                message: this.message,
                                author: 'client',
                                author_id: 0 // @todo
                            });
                        }

                        window.location.href = this.$root.config.url+'/login/thank-you';
                    }
                })
            }
        }
    }
</script>