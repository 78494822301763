<template>
    <ul class="tx-dftabs-tabMenu">
        <li :class="{'tx-dftabs-tabMenuEntry': true, 'tx-dftabs-tabMenuEntrySelected': local_value == item.value}" v-for="item in items" :key="item.value">
            <a href="#" @click.prevent="local_value = item.value">{{item.label}}</a>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'tabs',
        emits: ['update:modelValue'],
        props: {
            items: {
                type: Array
            },
            value: {
                type: String
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data: function ()
        {
            return {
                local_value: this.value
            }
        },
        watch: {
            local_value: function (value)
            {
                this.$emit('change', value);
            },
            value: function (value)
            {
                this.local_value = value;
            }
        },
        mounted: function ()
        {

        }
    }
</script>