<template>
    <div class="resort-locations" v-if="loaded">
        <locations-map zoom="12" :locations="locations" :center="resort.$attributes.location"></locations-map>
    </div>
</template>

<script>
    import Resort from "../../src/Resort";
    import LocationsMap from "./LocationsMap";
    import Chalet from "../../src/Chalet";
    import {FilterOperator} from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";

    export default {
        props: {
            uuid: {
                type: String
            }
        },
        data: function ()
        {
            return {
                resort: new Resort,
                loaded: false,
                chalets: []
            }
        },
        computed: {
            locations: function () {
                let locations = this.resort.$attributes.locations;

                for (let chalet of this.chalets)
                {
                    locations.push({
                        position: chalet.$attributes.location,
                        name: chalet.$attributes.name,
                        description: chalet.$attributes.description,
                        icon: this.$root.config.url+'/frontend/images/pin-chalet.png'
                    });

                    for (let location of chalet.$attributes.locations) {
                        locations.push({
                            position: location.position,
                            name: location.name,
                            description: location.description,
                            icon: this.$root.config.url+'/frontend/images/pin.png'
                        });
                    }
                }

                return locations;
            }
        },
        mounted: function ()
        {
            Resort.$query().find(this.uuid).then((resort) =>
            {
                this.resort = resort;
                this.loaded = true;

                Chalet.$query()
                    .filter('resort_id', FilterOperator.Equal, this.resort.$attributes.uuid)
                    .search()
                    .then((chalets) =>
                    {
                        this.chalets = chalets;
                    })
            });
        },
        components: {
            'locations-map': LocationsMap
        }
    }
</script>

<style lang="less">
    .resort-locations {
        padding-top: 50px;
    }
</style>