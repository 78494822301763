import {Model} from "@tailflow/laravel-orion/lib/model";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import Operator from "./Operator";

export default class OperatorContent extends Model<{}> {
    public $resource(): string {
        return "operator_content";
    }

    public $getKeyName() : string {
        return 'id';
    }

    public operator(): BelongsTo<Operator> {
        return new BelongsTo(Operator, this);
    }
}
