var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "resort-guide" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "resort_tabs" }, [
                _c(
                  "div",
                  { staticClass: "tx-dftabs-plugin1" },
                  [
                    _c("tabs", {
                      attrs: { items: _vm.tabs },
                      model: {
                        value: _vm.active_tab,
                        callback: function ($$v) {
                          _vm.active_tab = $$v
                        },
                        expression: "active_tab",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tx-dftabs-tabContents",
                        attrs: { id: "tx-dftabs-tabContents-1" },
                      },
                      [
                        _vm._l(
                          _vm.resort.$attributes.content,
                          function (content) {
                            return [
                              content.name == _vm.active_tab
                                ? [
                                    _c("div", {
                                      staticClass:
                                        "tx-dftabs-tabContent tx-dftabs-tabContentSelected",
                                      domProps: {
                                        innerHTML: _vm._s(content.content),
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }