var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "tx-dftabs-tabMenu" },
    _vm._l(_vm.items, function (item) {
      return _c(
        "li",
        {
          key: item.value,
          class: {
            "tx-dftabs-tabMenuEntry": true,
            "tx-dftabs-tabMenuEntrySelected": _vm.local_value == item.value,
          },
        },
        [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.local_value = item.value
                },
              },
            },
            [_vm._v(_vm._s(item.label))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }