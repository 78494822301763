// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vdpHeader {
  margin: 0;
}
.vdpComponent.vdpWithInput {
  width: 100%;
  display: block;
}
#flexslider-18 img {
  margin-top: unset !important;
}
.contact-social-media img {
  margin-top: 0;
  position: absolute;
}
`, "",{"version":3,"sources":["webpack://./public/frontend/src/main.vue","webpack://./main.vue"],"names":[],"mappings":"AACA;EACI,SAAA;ACAJ;ADGA;EACI,WAAA;EACA,cAAA;ACDJ;ADIA;EACI,4BAAA;ACFJ;ADKA;EAEQ,aAAA;EACA,kBAAA;ACJR","sourcesContent":["\n.vdpHeader {\n    margin: 0;\n}\n\n.vdpComponent.vdpWithInput {\n    width: 100%;\n    display: block;\n}\n\n#flexslider-18 img {\n    margin-top: unset !important;\n}\n\n.contact-social-media {\n    img {\n        margin-top: 0;\n        position: absolute;\n    }\n}\n",".vdpHeader {\n  margin: 0;\n}\n.vdpComponent.vdpWithInput {\n  width: 100%;\n  display: block;\n}\n#flexslider-18 img {\n  margin-top: unset !important;\n}\n.contact-social-media img {\n  margin-top: 0;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
