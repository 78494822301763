import {Model} from "@tailflow/laravel-orion/lib/model";

export default class Operator extends Model<{}> {
    public $resource(): string {
        return "operators";
    }

    public $getKeyName() : string {
        return 'uuid';
    }
}
