// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resort-locations {
  padding-top: 50px;
}
`, "",{"version":3,"sources":["webpack://./public/frontend/src/ResortLocations.vue","webpack://./ResortLocations.vue"],"names":[],"mappings":"AACA;EACI,iBAAA;ACAJ","sourcesContent":["\n.resort-locations {\n    padding-top: 50px;\n}\n",".resort-locations {\n  padding-top: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
