var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "chalet-locations-map" },
    [
      _c(
        "GmapMap",
        {
          staticStyle: { width: "100%", height: "700px" },
          attrs: {
            center: _vm.center,
            zoom: parseInt(_vm.zoom),
            "map-type-id": _vm.type,
          },
        },
        [
          _vm._l(_vm.map_locations, function (location, index) {
            return [
              _c("GmapMarker", {
                key: index,
                attrs: {
                  position: location.position,
                  clickable: true,
                  draggable: false,
                  icon: location.icon,
                },
                on: {
                  click: function ($event) {
                    return _vm.marker_click(location)
                  },
                },
              }),
            ]
          }),
          _vm._v(" "),
          _vm.infowindow
            ? _c("gmap-info-window", {
                attrs: {
                  options: _vm.infowindow_options,
                  position: _vm.infowindow.position,
                  opened: _vm.infowindow != null,
                },
                on: {
                  closeclick: function ($event) {
                    _vm.infowindow = null
                  },
                },
              })
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }