<template>
    <div class="resort-guide" v-if="loaded">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="resort_tabs">
                        <div class="tx-dftabs-plugin1">
                            <tabs :items="tabs" v-model="active_tab"></tabs>
                            <div class="tx-dftabs-tabContents" id="tx-dftabs-tabContents-1">
                                <template v-for="content in resort.$attributes.content">
                                    <template v-if="content.name == active_tab">
                                        <div class="tx-dftabs-tabContent tx-dftabs-tabContentSelected" v-html="content.content"></div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Resort from "../../src/Resort";
    import Tabs from './components/Tabs';
    import LocationsMap from "./LocationsMap";

    export default {
        props: {
            uuid: {
                type: String
            }
        },
        data: function ()
        {
            return {
                resort: new Resort,
                loaded: false,
                active_tab: 'Overview',
            }
        },
        mounted: function ()
        {
            Resort.$query().find(this.uuid).then((resort) =>
            {
                this.resort = resort;
                this.loaded = true;
            });
        },
        computed: {
            tabs: function () {
                let tabs = [];

                for (let content of this.resort.$attributes.content)
                {
                    tabs.push({
                        value: content.name,
                        label: content.name
                    })
                }

                return tabs;
            }
        },
        components: {
            'tabs': Tabs,
            'locations-map': LocationsMap
        }
    }
</script>