var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "currency_selection_box", name: "currency_selection_box" } },
    [
      _vm._v(" Show currencies in:   "),
      _c("span", { staticClass: "currencyLinks" }, [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.$root.currency = "GBP"
              },
            },
          },
          [_vm._v("GBP")]
        ),
        _vm._v(" |  "),
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.$root.currency = "CHF"
              },
            },
          },
          [_vm._v("CHF")]
        ),
        _vm._v(" |  "),
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.$root.currency = "AUD"
              },
            },
          },
          [_vm._v("AUD")]
        ),
        _vm._v(" |  "),
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.$root.currency = "EUR"
              },
            },
          },
          [_vm._v("EUR")]
        ),
        _vm._v(" |  "),
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.$root.currency = "BRL"
              },
            },
          },
          [_vm._v("BRL")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }