<template>
    <span>
        <template v-if="display_amount">{{format.format(display_amount)}}</template>
        <template v-else>...</template>

        {{display_symbol}}
    </span>
</template>

<script>
    export default {
        props: ['amount', 'symbol'],
        data: function ()
        {
            return {
                format: new Intl.NumberFormat('en-US'),
                display_amount: this.amount,
                display_symbol: this.symbol
            }
        },
        watch: {
            '$root.currency': function (symbol)
            {
                
                this.display_amount = '';
                this.display_symbol = symbol;

                if(this.symbol === 'EURO') {
                    this.symbol = 'EUR';
                }
                if(symbol === 'EURO') {
                    symbol = 'EUR';
                }
                var requestURL = 'https://api.exchangerate.host/convert?from='+this.symbol+'&to='+symbol+'&amount='+this.amount+'&access_key=39df21684d8b8c150c304603482c935b';
                var request = new XMLHttpRequest();
                request.open('GET', requestURL);
                request.responseType = 'json';
                request.send();

                request.onload = () =>
                {
                    this.display_amount = Math.round(request.response.result);
                }
            }
        }
    }
</script>