<template>
    <div class="relatedChalet col-md-3 col-xs-6 col-sm-4">
        <a :href="related_chalet.$attributes.url">
            <img style="height: 150px; object-fit: cover;" class="img-responsive" :src="related_chalet.$attributes.gallery[0]" width="278" height="186" alt="">
            <div class="infoBar">
                <span>{{related_chalet.$attributes.name}}</span>

                <template v-if="resort">
                    {{resort.$attributes.name}}
                </template>
            </div>
        </a>
    </div>
</template>

<script>
    import Resort from "../../src/Resort";

    export default {
        props: ['related_chalet'],
        data: function ()
        {
            return {
                resort: null
            }
        },
        mounted: function ()
        {
            Resort.$query().find(this.related_chalet.$attributes.resort_id).then((resort) =>
            {
                this.resort = resort;
            })
        }
    }
</script>