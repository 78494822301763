import {Model} from "@tailflow/laravel-orion/lib/model";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import Resort from "./Resort";
import {HasMany} from "@tailflow/laravel-orion/lib/drivers/default/relations/hasMany";
import SiteAssociation from "./SiteAssociation";

export default class Chalet extends Model<{}> {
    public $resource(): string {
        return "chalets";
    }

    public $getKeyName() : string {
        return 'uuid';
    }

    public resort(): BelongsTo<Resort> {
        return new BelongsTo(Resort, this);
    }

    public site_associations(): HasMany<SiteAssociation> {
        return new HasMany(SiteAssociation, this);
    }
}