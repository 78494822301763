var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "chalet" }, [
        _c(
          "div",
          {
            staticClass: "modal fade",
            attrs: {
              id: "enquiryForm",
              tabindex: "-1",
              role: "dialog",
              "aria-labelledby": "myModalLabel",
            },
          },
          [
            _c("chalet-enquiry-modal", {
              attrs: {
                id: _vm.chalet.$attributes.uuid,
                resort_id: _vm.chalet.$attributes.resort_id,
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "container oneCol chaletPage" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("div", { staticClass: "csc-default" }, [
                _c("div", { staticClass: "tx_sbchalets" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "chaletHeader",
                      attrs: { id: "chaletHeader" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "pull-left col-md-9 col-sm-12 chaletName",
                          },
                          [
                            _c("h1", [
                              _vm._v(_vm._s(_vm.chalet.$attributes.name)),
                            ]),
                            _vm._v(" "),
                            _c("h2", [
                              _vm._v(_vm._s(_vm.resort.$attributes.name)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "pull-right col-md-3 hidden-sm hidden-xs",
                          },
                          [
                            _c("div", { staticClass: "resortProperties" }, [
                              _c("p", [
                                _c("strong"),
                                _vm._v(" more properties in"),
                                _c("br"),
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.resort.$attributes.name)),
                                ]),
                                _c("br"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "viewResortLink" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: _vm.resort.$attributes.url },
                                  },
                                  [_vm._v("Click to View")]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { clear: "both" } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row chaletDetails" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-3 col-sm-4 chaletCapacity" },
                          [
                            _c("h4", [_vm._v("Chalet Capacity")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.chalet.$attributes.capacity_adults) +
                                  " Adults"
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.chalet.$attributes.capacity_children
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.chalet.$attributes.capacity_children
                                    ) + " Children"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3 col-sm-4 chaletHighlights" },
                          [
                            _c("h4", [_vm._v("Property Highlights")]),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "bodytext" },
                              [
                                _vm._l(
                                  _vm.chalet.$attributes.highlights,
                                  function (highlight) {
                                    return [
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(highlight)
                                      ),
                                      _c("br"),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3 col-sm-4 chaletCost" },
                          [
                            _c("h4", [_vm._v("Average Cost")]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticClass: "table",
                                attrs: { width: "100%" },
                              },
                              [
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", [_vm._v("High Season")]),
                                    _vm._v(" "),
                                    _vm.costs.high_season > 0
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(
                                                _vm.format.format(
                                                  _vm.costs.high_season
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.resort.$attributes
                                                  .resort_currency
                                              ) +
                                              "\n                                            "
                                          ),
                                        ])
                                      : _c("td", [
                                          _vm._v(
                                            "\n                                                POA\n                                            "
                                          ),
                                        ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [_vm._v("Mid Season")]),
                                    _vm._v(" "),
                                    _vm.costs.mid_season > 0
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(
                                                _vm.format.format(
                                                  _vm.costs.mid_season
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.resort.$attributes
                                                  .resort_currency
                                              ) +
                                              "\n                                            "
                                          ),
                                        ])
                                      : _c("td", [
                                          _vm._v(
                                            "\n                                                POA\n                                            "
                                          ),
                                        ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [_vm._v("Low Season")]),
                                    _vm._v(" "),
                                    _vm.costs.low_season > 0
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                                " +
                                              _vm._s(
                                                _vm.format.format(
                                                  _vm.costs.low_season
                                                )
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.resort.$attributes
                                                  .resort_currency
                                              ) +
                                              "\n                                            "
                                          ),
                                        ])
                                      : _c("td", [
                                          _vm._v(
                                            "\n                                                POA\n                                            "
                                          ),
                                        ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", { attrs: { colspan: "2" } }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "viewFullPriceList",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              _vm.active_tab = "availability"
                                            },
                                          },
                                        },
                                        [_vm._v("VIEW FULL PRICE LIST")]
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(1),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "csc-default", attrs: { id: "c3251" } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _c(
                          "div",
                          {
                            staticClass: "csc-default",
                            attrs: { id: "c3250" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "tx-dftabs-plugin1" },
                              [
                                _c("tabs", {
                                  attrs: { items: _vm.tabs },
                                  model: {
                                    value: _vm.active_tab,
                                    callback: function ($$v) {
                                      _vm.active_tab = $$v
                                    },
                                    expression: "active_tab",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "tx-dftabs-tabContents" },
                                  [
                                    _vm.active_tab == "overview"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tx-dftabs-tabContent tx-dftabs-tabContentSelected",
                                              attrs: {
                                                id: "tx-dftabs-tabContent0",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "csc-default",
                                                  attrs: { id: "c3262" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "csc-default",
                                                      attrs: { id: "c3261" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "row" },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "col-md-8",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                _vm.chalet
                                                                  .$attributes
                                                                  .content
                                                              ),
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-4 col-sm-4",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "csc-default",
                                                                  attrs: {
                                                                    id: "c3406",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "csc-textpic csc-textpic-left csc-textpic-above",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "csc-textpic-imagewrap",
                                                                          attrs:
                                                                            {
                                                                              "data-csc-images":
                                                                                "3",
                                                                              "data-csc-cols":
                                                                                "1",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm
                                                                            .chalet
                                                                            .$attributes
                                                                            .secondary_gallery,
                                                                          function (
                                                                            image
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "csc-textpic-imagerow",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "csc-textpic-imagecolumn csc-textpic-firstcol csc-textpic-lastcol",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "figure",
                                                                                      {
                                                                                        staticClass:
                                                                                          "csc-textpic-image csc-textpic-last",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: image,
                                                                                                width:
                                                                                                  "350",
                                                                                                height:
                                                                                                  "233",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.active_tab == "details"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tx-dftabs-tabContent",
                                              attrs: {
                                                id: "tx-dftabs-tabContent1",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "csc-default",
                                                  attrs: { id: "c3259" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tx_sbchalets",
                                                    },
                                                    [
                                                      _vm._m(2),
                                                      _vm._v(" "),
                                                      _c("h3", [
                                                        _vm._v(
                                                          "The quoted costs are inclusive of the following features and services: "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.features_steps,
                                                        function (
                                                          feature_step
                                                        ) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row chaletDatilsPage",
                                                              },
                                                              [
                                                                _vm._l(
                                                                  feature_step,
                                                                  function (
                                                                    group
                                                                  ) {
                                                                    return [
                                                                      _vm.operator
                                                                        ? [
                                                                            _vm
                                                                              .catering
                                                                              .length &&
                                                                            group.name ==
                                                                              "Catering"
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-md-4",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "padding-bottom":
                                                                                          "30px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "h2",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Catering"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "ul",
                                                                                      {
                                                                                        staticClass:
                                                                                          "list-group",
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.catering,
                                                                                        function (
                                                                                          feature_id
                                                                                        ) {
                                                                                          return _c(
                                                                                            "li",
                                                                                            {
                                                                                              staticClass:
                                                                                                "list-group-item",
                                                                                            },
                                                                                            [
                                                                                              _vm._l(
                                                                                                _vm.features,
                                                                                                function (
                                                                                                  feature
                                                                                                ) {
                                                                                                  return [
                                                                                                    feature
                                                                                                      .$attributes
                                                                                                      .id ==
                                                                                                    feature_id
                                                                                                      ? [
                                                                                                          _vm._v(
                                                                                                            "\n                                                                                                    " +
                                                                                                              _vm._s(
                                                                                                                feature
                                                                                                                  .$attributes
                                                                                                                  .name
                                                                                                              ) +
                                                                                                              "\n                                                                                                "
                                                                                                          ),
                                                                                                        ]
                                                                                                      : _vm._e(),
                                                                                                  ]
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      group.name !=
                                                                        "Luxe Services" &&
                                                                      group.name !=
                                                                        "Catering"
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "col-md-4",
                                                                              staticStyle:
                                                                                {
                                                                                  "padding-bottom":
                                                                                    "30px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "h2",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      group.name
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "ul",
                                                                                {
                                                                                  staticClass:
                                                                                    "list-group",
                                                                                },
                                                                                _vm._l(
                                                                                  group.values,
                                                                                  function (
                                                                                    value
                                                                                  ) {
                                                                                    return _c(
                                                                                      "li",
                                                                                      {
                                                                                        staticClass:
                                                                                          "list-group-item",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              value
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _vm.operator &&
                                                                      group.name ==
                                                                        "Luxe Services"
                                                                        ? [
                                                                            _vm
                                                                              .luxe_services
                                                                              .length
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "col-md-4",
                                                                                    staticStyle:
                                                                                      {
                                                                                        "padding-bottom":
                                                                                          "30px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "h2",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "Luxe Services"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "ul",
                                                                                      {
                                                                                        staticClass:
                                                                                          "list-group",
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.luxe_services,
                                                                                        function (
                                                                                          feature_id
                                                                                        ) {
                                                                                          return _c(
                                                                                            "li",
                                                                                            {
                                                                                              staticClass:
                                                                                                "list-group-item",
                                                                                            },
                                                                                            [
                                                                                              _vm._l(
                                                                                                _vm.features,
                                                                                                function (
                                                                                                  feature
                                                                                                ) {
                                                                                                  return [
                                                                                                    feature
                                                                                                      .$attributes
                                                                                                      .id ==
                                                                                                    feature_id
                                                                                                      ? [
                                                                                                          _vm._v(
                                                                                                            "\n                                                                                                    " +
                                                                                                              _vm._s(
                                                                                                                feature
                                                                                                                  .$attributes
                                                                                                                  .name
                                                                                                              ) +
                                                                                                              "\n                                                                                                "
                                                                                                          ),
                                                                                                        ]
                                                                                                      : _vm._e(),
                                                                                                  ]
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        : _vm._e(),
                                                                    ]
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.active_tab == "floorplans"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tx-dftabs-tabContent",
                                              staticStyle: { padding: "unset" },
                                              attrs: {
                                                id: "tx-dftabs-tabContent2",
                                              },
                                            },
                                            [
                                              _vm.chalet.$attributes.floorplans
                                                .length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "csc-default",
                                                      attrs: { id: "c3258" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "csc-textpic csc-textpic-left csc-textpic-above",
                                                        },
                                                        _vm._l(
                                                          _vm.chalet.$attributes
                                                            .floorplans,
                                                          function (floorplan) {
                                                            return _c("img", {
                                                              attrs: {
                                                                src: floorplan,
                                                              },
                                                            })
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.chalet.$attributes.rooms
                                                .length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "csc-default",
                                                      attrs: { id: "c3257" },
                                                    },
                                                    [
                                                      _vm._m(3),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.chalet.$attributes
                                                          .rooms,
                                                        function (room) {
                                                          return _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "bodytext",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-weight":
                                                                      "bold",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      room.name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    room.description
                                                                  ) +
                                                                  "\n                                                        "
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.active_tab == "reviews"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tx-dftabs-tabContent",
                                              attrs: {
                                                id: "tx-dftabs-tabContent3",
                                              },
                                            },
                                            [
                                              _vm.press_reviews.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "csc-default",
                                                      attrs: { id: "c13259" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row reviewBlock",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-12",
                                                            },
                                                            [
                                                              _c(
                                                                "h1",
                                                                {
                                                                  staticClass:
                                                                    "whatPress reviewH1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "FROM THE PRESS"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.press_reviews,
                                                                function (
                                                                  review
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "csc-default",
                                                                        attrs: {
                                                                          id: "c13258",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "bodytext",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "“" +
                                                                                _vm._s(
                                                                                  review.review
                                                                                ) +
                                                                                "”"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "h6",
                                                                          {
                                                                            staticClass:
                                                                              "align-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                review.author
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "csc-default greyDivider",
                                                                      },
                                                                      [
                                                                        _vm._m(
                                                                          4,
                                                                          true
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.own_reviews.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "csc-default",
                                                      attrs: { id: "c13253" },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row reviewBlock",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-md-12",
                                                            },
                                                            [
                                                              _c(
                                                                "h1",
                                                                {
                                                                  staticClass:
                                                                    "whatWe reviewH1",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "WHAT WE THINK"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm._l(
                                                                _vm.own_reviews,
                                                                function (
                                                                  review
                                                                ) {
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "csc-default",
                                                                        attrs: {
                                                                          id: "c13252",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "h3",
                                                                          {
                                                                            staticClass:
                                                                              "align-left",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    "font-style":
                                                                                      "italic",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    review.review
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "h6",
                                                                          {
                                                                            staticClass:
                                                                              "align-left",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                review.author
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "csc-default greyDivider",
                                                                      },
                                                                      [
                                                                        _vm._m(
                                                                          5,
                                                                          true
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.active_tab == "location"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tx-dftabs-tabContent",
                                              attrs: {
                                                id: "tx-dftabs-tabContent4",
                                              },
                                            },
                                            [
                                              _vm.resort
                                                ? _c("locations-map", {
                                                    attrs: {
                                                      type: "roadmap",
                                                      height: "400",
                                                      zoom: 13,
                                                      locations: _vm.locations,
                                                      center: _vm.center,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "csc-default",
                                                  attrs: { id: "c3255" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tx_sbchalets",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row chaletLocationTable",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-sm-4",
                                                            },
                                                            [
                                                              _vm._m(6),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._l(
                                                                    _vm.chalet
                                                                      .$attributes
                                                                      .chalet_locations,
                                                                    function (
                                                                      location
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "chaletLocationCol1label",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    location.name
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              location.description
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                      ]
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-sm-4",
                                                            },
                                                            [
                                                              _vm._m(7),
                                                              _vm._v(" "),
                                                              _c(
                                                                "p",
                                                                [
                                                                  _vm._l(
                                                                    _vm.chalet
                                                                      .$attributes
                                                                      .airport_locations,
                                                                    function (
                                                                      location
                                                                    ) {
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "chaletLocationCol1label",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    location.name.replace(
                                                                                      ": ;",
                                                                                      ":"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              location.description
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "br"
                                                                        ),
                                                                      ]
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-sm-4",
                                                            },
                                                            [
                                                              _vm._m(8),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mapLegendDiv",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "chaletLocationCol3label",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src:
                                                                                _vm
                                                                                  .$root
                                                                                  .config
                                                                                  .url +
                                                                                "/frontend/images/legendpropertyicon.png",
                                                                              width:
                                                                                "13px",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " Property location "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "mapLegendDiv",
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "chaletLocationCol3label",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src:
                                                                                _vm
                                                                                  .$root
                                                                                  .config
                                                                                  .url +
                                                                                "/frontend/images/legendskilifticon.png",
                                                                              width:
                                                                                "13px",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(
                                                                    " Ski lift "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "clickmapicons",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Click on icons for further info"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.active_tab == "gastronome"
                                      ? [
                                          _c("div", {
                                            staticClass: "tx-dftabs-tabContent",
                                            attrs: {
                                              id: "tx-dftabs-tabContent5",
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(_vm.gastronome),
                                            },
                                          }),
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.active_tab == "availability"
                                      ? [_vm._t("default")]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.related.length
                                  ? [
                                      _vm._m(9),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "csc-default",
                                          attrs: { id: "c8243" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tx_sbchalets" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "related_chalets row",
                                                },
                                                _vm._l(
                                                  _vm.related,
                                                  function (related_chalet) {
                                                    return _c(
                                                      "chalet-recommendation",
                                                      {
                                                        key: related_chalet.uuid,
                                                        attrs: {
                                                          related_chalet:
                                                            related_chalet,
                                                        },
                                                      }
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "csc-default",
                            attrs: { id: "c1300" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "stickyChaletBlock" },
                              [
                                _c("chalet-enquiry-form", {
                                  attrs: { chalet: _vm.chalet },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "csc-default",
                                    attrs: { id: "c8364" },
                                  },
                                  [
                                    _c("div", { staticClass: "row" }, [
                                      _vm._m(10),
                                      _vm._v(" "),
                                      _vm.chalet.$attributes.catalogue
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-md-12 chaletRightCatalogue",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.chalet.$attributes
                                                      .catalogue,
                                                  },
                                                },
                                                [_vm._v("Download Catalogue")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm._m(11),
                                      _vm._v(" "),
                                      _vm._m(12),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "csc-default",
                                    attrs: { id: "c20483" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "csc-textpic csc-textpic-center csc-textpic-above",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "csc-textpic-imagewrap",
                                            attrs: {
                                              "data-csc-images": "1",
                                              "data-csc-cols": "2",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "csc-textpic-center-outer",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "csc-textpic-center-inner",
                                                  },
                                                  [
                                                    _c(
                                                      "figure",
                                                      {
                                                        staticClass:
                                                          "csc-textpic-image csc-textpic-last",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src:
                                                              _vm.$root.config
                                                                .url +
                                                              "/frontend/images/PricePromise_Chalet.jpg",
                                                            width: "270",
                                                            height: "344",
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "csc-default",
                          attrs: { id: "c1301" },
                        }),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("ul", { staticClass: "tabs" }, [
        _c("li", [
          _vm._v("["),
          _c(
            "a",
            {
              attrs: {
                href: "resorts/switzerland/gstaad/chalet-lottie/?tx_sbchalets_pi1%5Baction%5D=header&tx_sbchalets_pi1%5Bcontroller%5D=DetailView",
              },
            },
            [_vm._v("header")]
          ),
          _vm._v("]"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("["),
          _c(
            "a",
            {
              attrs: {
                href: "resorts/switzerland/gstaad/chalet-lottie/?tx_sbchalets_pi1%5Baction%5D=details&tx_sbchalets_pi1%5Bcontroller%5D=DetailView",
              },
            },
            [_vm._v("details")]
          ),
          _vm._v("]"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("["),
          _c(
            "a",
            {
              attrs: {
                href: "resorts/switzerland/gstaad/chalet-lottie/?tx_sbchalets_pi1%5Baction%5D=availability&tx_sbchalets_pi1%5Bcontroller%5D=DetailView",
              },
            },
            [_vm._v("availability")]
          ),
          _vm._v("]"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { clear: "both" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "col-md-3 hidden-sm hidden-xs chaletAvailable text-center",
      },
      [
        _c("div", { staticClass: "greyCallBar" }),
        _vm._v(" "),
        _c("strong", [_vm._v("WE'RE AVAILABLE 24/7")]),
        _c("br"),
        _vm._v(" Our phone line is open 7 days a week"),
        _c("br"),
        _vm._v(" Mon to Fri 8.30am to 6pm"),
        _c("br"),
        _vm._v(" Sat 9am to 5pm & Sun 10am to 4pm"),
        _c("br"),
        _vm._v(" "),
        _c("a", { attrs: { href: "tel:+442032827561" } }, [
          _vm._v("CALL US: +44 203 282 7561"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("ul", { staticClass: "tabs" }, [
        _c("li", [
          _vm._v("["),
          _c(
            "a",
            {
              attrs: {
                href: "resorts/switzerland/klosters/chalet-eugenia/?tx_sbchalets_pi1%5Baction%5D=header&tx_sbchalets_pi1%5Bcontroller%5D=DetailView",
              },
            },
            [_vm._v("header")]
          ),
          _vm._v("]"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("["),
          _c(
            "a",
            {
              attrs: {
                href: "resorts/switzerland/klosters/chalet-eugenia/?tx_sbchalets_pi1%5Baction%5D=details&tx_sbchalets_pi1%5Bcontroller%5D=DetailView",
              },
            },
            [_vm._v("details")]
          ),
          _vm._v("]"),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("["),
          _c(
            "a",
            {
              attrs: {
                href: "resorts/switzerland/klosters/chalet-eugenia/?tx_sbchalets_pi1%5Baction%5D=availability&tx_sbchalets_pi1%5Bcontroller%5D=DetailView",
              },
            },
            [_vm._v("availability")]
          ),
          _vm._v("]"),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { clear: "both" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "csc-header csc-header-n2" }, [
      _c("h4", { staticClass: "csc-header-alignment-left" }, [
        _vm._v("BEDROOMS"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "divider" }, [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "divider" }, [_c("hr")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", [_c("b", [_vm._v("Chalet Location")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", [_c("b", [_vm._v("Nearest Airports")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h6", [_c("b", [_vm._v("Map key")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "csc-default", attrs: { id: "c1371" } }, [
      _c("h5", [
        _c("b", [_vm._v(" LIKE THIS CHALET?")]),
        _vm._v(" WE RECOMMEND:"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12 chaletRightAvailability" }, [
      _c("a", { attrs: { onclick: "active_tab = 'availability'" } }, [
        _vm._v("View Availability"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-md-12 sendChaletToFriends visible-lg-12" },
      [
        _c(
          "a",
          {
            attrs: {
              href: "mailto:?subject=Chalet Shalimar%20-%20A%20great%20option%20for%20our%20ski%20trip&body=Hi,%20I’ve%20been%20having%20a%20good%20look%20around%20the%20web%20and%20I’ve%20found%20this%20chalet:%0D%0A%0D%0AChalet Shalimar%20-%20https%3A%2F%2Fwww.ski-boutique.co.uk%2Fresorts%2Fswitzerland%2Fzermatt%2Fchalet-shalimar%2F",
            },
          },
          [_vm._v("SEND THIS CHALET TO FRIENDS")]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-md-12 boutiqueBenefits visible-lg-12" },
      [
        _c("h5", [_vm._v("Boutique Benefits")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("LOCAL EXPERTISE")]),
          _vm._v(" "),
          _c("li", [_vm._v("GUEST SUPPORT ")]),
          _vm._v(" "),
          _c("li", [_vm._v("BEST RATES")]),
          _vm._v(" "),
          _c("li", [_vm._v("FRIENDLY ADVICE")]),
          _vm._v(" "),
          _c("li", [_vm._v("SKIBOUTIQUE PA")]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }