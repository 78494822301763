<template>
    <div class="modal fade in" id="enquiryForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="false" style="display: block; padding-right: 17px;">
        <div class="modal-backdrop fade in" style="height: 100%"></div>
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button @click.prevent="$emit('close')" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h2 class="modal-title" id="enquiryFormLabel">QUICK ENQUIRY</h2>
                    <div class="divider">
                        <hr>
                    </div>
                    <h4>TELL US WHAT YOU WANT - WE'LL DO THE REST</h4>
                </div>
                <div class="modal-body">
                    <form data-parsley-validate="" action="?tx_sbchalets_pi1%5Bcontroller%5D=Booking" method="post">
                        <div> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@extension]" value="SbChalets" /> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@vendor]" value="SkiBoutique" /> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@controller]" value="Booking" /> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@action]" value="enquiryPartial" /> <input type="hidden" name="tx_sbchalets_pi1[__referrer][arguments]" value="YTowOnt98f9b108764c374708c2d4451230d654eaa11b20e" /> <input type="hidden" name="tx_sbchalets_pi1[__referrer][@request]" value="a:4:{s:10:&quot;@extension&quot;;s:9:&quot;SbChalets&quot;;s:11:&quot;@controller&quot;;s:7:&quot;Booking&quot;;s:7:&quot;@action&quot;;s:14:&quot;enquiryPartial&quot;;s:7:&quot;@vendor&quot;;s:11:&quot;SkiBoutique&quot;;}81df9b2de54bd60dcc1a9da6aabf81c2e98f3fe6" /> <input type="hidden" name="tx_sbchalets_pi1[__trustedProperties]" value="a:14:{s:4:&quot;name&quot;;i:1;s:9:&quot;last_name&quot;;i:1;s:5:&quot;email&quot;;i:1;s:9:&quot;telephone&quot;;i:1;s:12:&quot;arrival_date&quot;;i:1;s:6:&quot;adults&quot;;i:1;s:8:&quot;children&quot;;i:1;s:14:&quot;enquiry_source&quot;;i:1;s:10:&quot;preference&quot;;i:1;s:4:&quot;text&quot;;i:1;s:7:&quot;keyword&quot;;i:1;s:8:&quot;campaign&quot;;i:1;s:2:&quot;ad&quot;;i:1;s:18:&quot;sendenquiryPartial&quot;;i:1;}961c6d424770ebc186d8ed3b45219f012cfd8c65" /> </div>

                        <div class="row">
                        <div class="form-group col-xs-6"> <input v-model="first_name" :class="{'form-control': true, 'parsley-error': errors.indexOf('first_name') !== -1}" placeholder="Name" type="text" name="tx_sbchalets_pi1[name]" required="required" /> </div>
                        <div class="form-group col-xs-6"> <input v-model="last_name" :class="{'form-control': true, 'parsley-error': errors.indexOf('last_name') !== -1}" placeholder="Surname" type="text" name="tx_sbchalets_pi1[last_name]" required="required" /> </div>
                        <div class="form-group col-xs-12"> <input v-model="email" :class="{'form-control': true, 'parsley-error': errors.indexOf('email') !== -1}" placeholder="Email" type="text" name="tx_sbchalets_pi1[email]" required="required" /> </div>
                        <div class="form-group col-xs-12"> <input v-model="phone" :class="{'form-control': true, 'parsley-error': errors.indexOf('phone') !== -1}" placeholder="Telephone" type="text" name="tx_sbchalets_pi1[telephone]" /> </div>
                        <div class="form-group col-xs-12">
                            <date-picker ref="arrival_date" v-model="arrival_date" :inputAttributes="{'placeholder': 'Arrival Date', 'class': {'form-control': true, 'arrival_date': true, 'parsley-error': errors.indexOf('arrival_date') !== -1}}"></date-picker>
                        </div>



                        <div class="form-group col-xs-6">
                            <select required="" v-model="adults" :class="{'form-control': true, 'parsley-error': errors.indexOf('adults') !== -1}" name="tx_sbchalets_pi1[adults]">
                                <option value="">Adults</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div class="form-group col-xs-6">
                            <select required="" :class="{'form-control': true, 'parsley-error': errors.indexOf('children') !== -1}" name="tx_sbchalets_pi1[children]" v-model="children">
                                <option value="0">Children</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div class="form-group hidden">
                            <select class="form-control" name="tx_sbchalets_pi1[enquiry_source]">
                                <option value="-1">Enquiry source</option>
                                <option value="0" selected="selected">Website - Perfect Chalet</option>
                                <option value="1">Website - Chalet Enquire Now</option>
                                <option value="2">Website - Chalet Price List</option>
                                <option value="3">Telephone</option>
                                <option value="4">Direct email</option>
                                <option value="5">Repeat guest</option>
                                <option value="6">Referral</option>
                                <option value="7">LinkedIn</option>
                                <option value="8">Facebook</option>
                                <option value="9">Website - Next Season</option>
                            </select>
                        </div>
                        <div class="form-group col-xs-12" v-if="resort_id">
                            <select required="" :class="{'form-control': true, 'parsley-error': errors.indexOf('flexible') !== -1}" v-model="flexible">
                                <option value="">Resort Preference</option>
                                <option value="0">I'm only interested in this resort</option>
                                <option value="1" selected="selected">I'm flexible on resort</option>
                            </select>
                        </div>
                        <div class="form-group col-xs-12">
                            <textarea rows="5" cols="20" v-model="message" placeholder="TELL US WHAT YOU'RE LOOKING FOR IN ONE SHORT PARAGRAPH (A ROUGH BUDGET INDICATION  IS VERY HELPFUL)" class="form-control" name="tx_sbchalets_pi1[text]"></textarea> </div>
                        <div class="form-group col-xs-12"> </div>
                        <input type="hidden" name="tx_sbchalets_pi1[keyword]" value="" /> <input type="hidden" name="tx_sbchalets_pi1[campaign]" value="" /> <input type="hidden" name="tx_sbchalets_pi1[ad]" value="" />
                        <div class="col-xs-12">
                            <input class="btn btn-default" type="submit" @click.prevent="submit" value="Send" />
                        </div>
                    </div>
                    </form>
                </div>
                <div class="modal-footer"> </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Enquiry from "../../src/Enquiry";
    import EnquiryMessage from "../../src/EnquiryMessage";

    import DatePick from 'vue-date-pick';
    import 'vue-date-pick/dist/vueDatePick.css';

    export default {
        props: {
            id: {
                type: String
            },
            resort_id: {
                type: String
            }
        },
        components: {
            'date-picker': DatePick
        },
        data: function ()
        {
            return {
                errors: [],
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                arrival_date: '',
                adults: '',
                children: '',
                flexible: '',
                message: '',
            }
        },
        methods: {
            submit: function ()
            {
                this.errors = [];

                Enquiry.$query().store({
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    phone: this.phone,
                    arrival_date: this.$refs.arrival_date.value,
                    adults: this.adults,
                    children: this.children,
                    flexible: this.flexible,
                    chalet_id: this.id,
                    resort_id: this.resort_id,
                    hash: Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 32)
                }).catch((e) =>
                {
                    this.errors = Object.keys(e.response.data.errors);
                })
                .then((enquiry) => {
                    if (this.errors.length == 0)
                    {
                        if (this.message)
                        {
                            EnquiryMessage.$query().store({
                                enquiry_id: enquiry.$attributes.id,
                                message: this.message,
                                author: 'client',
                                author_id: 0 // @todo
                            });
                        }

                        window.location.href = this.$root.config.url+'/login/thank-you';
                    }
                })
            }
        }
    }
</script>