var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.display_amount
        ? [_vm._v(_vm._s(_vm.format.format(_vm.display_amount)))]
        : [_vm._v("...")],
      _vm._v("\n\n    " + _vm._s(_vm.display_symbol) + "\n"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }