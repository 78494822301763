import {Model} from "@tailflow/laravel-orion/lib/model";
import {BelongsTo} from "@tailflow/laravel-orion/lib/drivers/default/relations/belongsTo";
import Site from "./Site";

export default class SiteAssociation extends Model<{}> {
    public $resource(): string {
        return "site_associations";
    }

    public $getKeyName() : string {
        return 'id';
    }

    public resort(): BelongsTo<Site> {
        return new BelongsTo(Site, this);
    }
}