<template>
    <div class="chalet" v-if="loaded">
        <div class="modal fade" id="enquiryForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
            <chalet-enquiry-modal :id="chalet.$attributes.uuid" :resort_id="chalet.$attributes.resort_id"></chalet-enquiry-modal>
        </div>

        <div class="container oneCol chaletPage">
            <div class="row">
                <div class="col-md-12">
                    <!-- CONTENT ELEMENT, uid:3162/list [begin] -->
                    <div class="csc-default">
                        <!-- Plugin inserted: [begin] -->
                        <div class="tx_sbchalets">
                            <div>
                                <ul class="tabs">
                                    <li>[<a href="resorts/switzerland/gstaad/chalet-lottie/?tx_sbchalets_pi1%5Baction%5D=header&amp;tx_sbchalets_pi1%5Bcontroller%5D=DetailView">header</a>]</li>
                                    <li>[<a href="resorts/switzerland/gstaad/chalet-lottie/?tx_sbchalets_pi1%5Baction%5D=details&amp;tx_sbchalets_pi1%5Bcontroller%5D=DetailView">details</a>]</li>
                                    <li>[<a href="resorts/switzerland/gstaad/chalet-lottie/?tx_sbchalets_pi1%5Baction%5D=availability&amp;tx_sbchalets_pi1%5Bcontroller%5D=DetailView">availability</a>]</li>
                                </ul>
                                <div style="clear: both;"></div>
                            </div>
                            <div class="chaletHeader" id="chaletHeader">
                                <div class="row">
                                    <div class="pull-left col-md-9 col-sm-12 chaletName">
                                        <h1>{{chalet.$attributes.name}}</h1>
                                        <h2>{{resort.$attributes.name}}</h2>
                                    </div>
                                    <div class="pull-right col-md-3 hidden-sm hidden-xs ">
                                        <div class=" resortProperties">
                                            <p> <strong></strong> more properties in<br><strong>{{resort.$attributes.name}}</strong><br> </p>
                                            <p class="viewResortLink"> <a :href="resort.$attributes.url">Click to View</a> </p>
                                        </div>
                                    </div>
                                </div>
                                <div style="clear: both;"></div>
                                <div class="row chaletDetails">
                                    <div class="col-md-3 col-sm-4 chaletCapacity">
                                        <h4>Chalet Capacity</h4>
                                        <p>{{chalet.$attributes.capacity_adults}} Adults</p>

                                        <p v-if="chalet.$attributes.capacity_children">{{chalet.$attributes.capacity_children}} Children</p>
                                    </div>
                                    <div class="col-md-3 col-sm-4 chaletHighlights">
                                        <h4>Property Highlights</h4>
                                        <p class="bodytext">
                                            <template v-for="highlight in chalet.$attributes.highlights">
                                                {{highlight}}<br>
                                            </template>
                                        </p>
                                    </div>
                                    <div class="col-md-3 col-sm-4 chaletCost">
                                        <h4>Average Cost</h4>
                                        <table width="100%" class="table">
                                            <tbody>
                                            <tr>
                                                <td>High Season</td>
                                                <td v-if="costs.high_season > 0">
                                                    {{format.format(costs.high_season)}} {{resort.$attributes.resort_currency}}
                                                </td>
                                                <td v-else>
                                                    POA
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Mid Season</td>
                                                <td v-if="costs.mid_season > 0">
                                                    {{format.format(costs.mid_season)}} {{resort.$attributes.resort_currency}}
                                                </td>
                                                <td v-else>
                                                    POA
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Low Season</td>
                                                <td v-if="costs.low_season > 0">
                                                    {{format.format(costs.low_season)}} {{resort.$attributes.resort_currency}}
                                                </td>
                                                <td v-else>
                                                    POA
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"><a @click.prevent="active_tab = 'availability'" class="viewFullPriceList">VIEW FULL PRICE LIST</a></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3 hidden-sm hidden-xs chaletAvailable text-center">
                                        <div class="greyCallBar"></div>
                                        <strong>WE'RE AVAILABLE 24/7</strong><br> Our phone line is open 7 days a week<br> Mon to Fri 8.30am to 6pm<br> Sat 9am to 5pm &amp; Sun 10am to 4pm<br> <a href="tel:+442032827561">CALL US: +44 203 282 7561</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Plugin inserted: [end] -->

                        <div id="c3251" class="csc-default">
                            <div class="row">
                                <div class="col-md-9">
                                    <!-- CONTENT ELEMENT, uid:3250/list [begin] -->
                                    <div id="c3250" class="csc-default">
                                        <!-- Plugin inserted: [begin] --> <!-- BEGIN: Content of extension "df_tabs", plugin "tx-dftabs-plugin1" -->
                                        <div class="tx-dftabs-plugin1">
                                            <tabs :items="tabs" v-model="active_tab"></tabs>

                                            <div class="tx-dftabs-tabContents">
                                                <template v-if="active_tab == 'overview'">
                                                    <div class="tx-dftabs-tabContent tx-dftabs-tabContentSelected" id="tx-dftabs-tabContent0">
                                                        <div id="c3262" class="csc-default">
                                                            <div id="c3261" class="csc-default">
                                                                <div class="row">
                                                                    <div class="col-md-8" v-html="chalet.$attributes.content"></div>

                                                                    <div class="col-md-4 col-sm-4">
                                                                        <div id="c3406" class="csc-default">
                                                                            <div class="csc-textpic csc-textpic-left csc-textpic-above">
                                                                                <div class="csc-textpic-imagewrap" data-csc-images="3" data-csc-cols="1">
                                                                                    <div class="csc-textpic-imagerow" v-for="image in chalet.$attributes.secondary_gallery">
                                                                                        <div class="csc-textpic-imagecolumn csc-textpic-firstcol csc-textpic-lastcol">
                                                                                            <figure class="csc-textpic-image csc-textpic-last"><img :src="image" width="350" height="233"></figure>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-if="active_tab == 'details'">
                                                    <div class="tx-dftabs-tabContent" id="tx-dftabs-tabContent1">
                                                        <!-- CONTENT ELEMENT, uid:3259/list [begin] -->
                                                        <div id="c3259" class="csc-default">
                                                            <!-- Plugin inserted: [begin] -->
                                                            <div class="tx_sbchalets">
                                                                <div>
                                                                    <ul class="tabs">
                                                                        <li>[<a href="resorts/switzerland/klosters/chalet-eugenia/?tx_sbchalets_pi1%5Baction%5D=header&amp;tx_sbchalets_pi1%5Bcontroller%5D=DetailView">header</a>]</li>
                                                                        <li>[<a href="resorts/switzerland/klosters/chalet-eugenia/?tx_sbchalets_pi1%5Baction%5D=details&amp;tx_sbchalets_pi1%5Bcontroller%5D=DetailView">details</a>]</li>
                                                                        <li>[<a href="resorts/switzerland/klosters/chalet-eugenia/?tx_sbchalets_pi1%5Baction%5D=availability&amp;tx_sbchalets_pi1%5Bcontroller%5D=DetailView">availability</a>]</li>
                                                                    </ul>
                                                                    <div style="clear: both;"></div>
                                                                </div>
                                                                <h3>The quoted costs are inclusive of the following features and services: </h3>

                                                                <template v-for="feature_step in features_steps">
                                                                        <div class="row chaletDatilsPage">
                                                                            <template v-for="group in feature_step">

                                                                                <template v-if="operator">
                                                                                    <div class="col-md-4" v-if="catering.length && group.name == 'Catering'" style="padding-bottom: 30px">
                                                                                        <h2>Catering</h2>
                                                                                        <ul class="list-group">
                                                                                            <li class="list-group-item" v-for="feature_id in catering">
                                                                                                <template v-for="feature in features">
                                                                                                    <template v-if="feature.$attributes.id == feature_id">
                                                                                                        {{feature.$attributes.name}}
                                                                                                    </template>
                                                                                                </template>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </template>

                                                                                <div class="col-md-4" v-if="group.name != 'Luxe Services' && group.name != 'Catering'" style="padding-bottom: 30px">
                                                                                    <h2>{{group.name}}</h2>
                                                                                    <ul class="list-group">
                                                                                        <li class="list-group-item" v-for="value in group.values"> {{value}} </li>
                                                                                    </ul>
                                                                                </div>

                                                                                <template v-if="operator && group.name == 'Luxe Services'">
                                                                                    <div class="col-md-4" v-if="luxe_services.length" style="padding-bottom: 30px">
                                                                                        <h2>Luxe Services</h2>
                                                                                        <ul class="list-group">
                                                                                            <li class="list-group-item" v-for="feature_id in luxe_services">
                                                                                                <template v-for="feature in features">
                                                                                                    <template v-if="feature.$attributes.id == feature_id">
                                                                                                        {{feature.$attributes.name}}
                                                                                                    </template>
                                                                                                </template>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </template>
                                                                            </template>
                                                                        </div>
                                                                </template>
                                                            </div>
                                                            <!-- Plugin inserted: [end] -->
                                                        </div>
                                                        <!-- CONTENT ELEMENT, uid:3259/list [end] -->
                                                    </div>
                                                </template>

                                                <template v-if="active_tab == 'floorplans'">
                                                    <div class="tx-dftabs-tabContent" id="tx-dftabs-tabContent2" style="padding: unset;">
                                                        <!-- CONTENT ELEMENT, uid:3258/image [begin] -->
                                                        <div id="c3258" class="csc-default" v-if="chalet.$attributes.floorplans.length">
                                                            <!-- Image block: [begin] -->
                                                            <div class="csc-textpic csc-textpic-left csc-textpic-above">
                                                                <img v-for="floorplan in chalet.$attributes.floorplans" :src="floorplan">
                                                            </div>
                                                            <!-- Image block: [end] -->
                                                        </div>



                                                        <div id="c3257" class="csc-default" v-if="chalet.$attributes.rooms.length">
                                                            <!-- Header: [begin] -->
                                                            <div class="csc-header csc-header-n2">
                                                                <h4 class="csc-header-alignment-left">BEDROOMS</h4>
                                                            </div>
                                                            <!-- Header: [end] --> <!-- Text: [begin] -->
                                                            <p class="bodytext" v-for="room in chalet.$attributes.rooms">
                                                                <span style="font-weight: bold;">{{room.name}}</span> {{room.description}}
                                                            </p>
                                                            <!-- Text: [end] -->
                                                        </div>
                                                        <!-- CONTENT ELEMENT, uid:3257/text [end] -->
                                                    </div>
                                                </template>

                                                <template v-if="active_tab == 'reviews'">
                                                    <div class="tx-dftabs-tabContent" id="tx-dftabs-tabContent3">
                                                        <div id="c13259" class="csc-default" v-if="press_reviews.length">
                                                            <div class="row reviewBlock">
                                                                <div class="col-md-12">
                                                                    <h1 class="whatPress reviewH1">FROM THE PRESS</h1>
                                                                    <!-- CONTENT ELEMENT, uid:13257/div [end] --> <!-- CONTENT ELEMENT, uid:13258/text [begin] -->

                                                                    <template v-for="review in press_reviews">
                                                                        <div id="c13258" class="csc-default">
                                                                            <!-- Text: [begin] -->
                                                                            <p class="bodytext">“{{review.review}}”</p>
                                                                            <h6 class="align-left">{{review.author}}</h6>
                                                                            <!-- Text: [end] -->
                                                                        </div>
                                                                        <!-- CONTENT ELEMENT, uid:13258/text [end] --> <!-- CONTENT ELEMENT, uid:15587/div [begin] -->
                                                                        <div class="csc-default greyDivider">
                                                                            <!-- Div element [begin] -->
                                                                            <div class="divider">
                                                                                <hr>
                                                                            </div>
                                                                            <!-- Div element [end] -->
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- CONTENT ELEMENT, uid:13259/gridelements_pi1 [end] --> <!-- CONTENT ELEMENT, uid:13253/gridelements_pi1 [begin] -->


                                                        <div id="c13253" class="csc-default" v-if="own_reviews.length">
                                                            <div class="row reviewBlock">
                                                                <div class="col-md-12">
                                                                    <h1 class="whatWe reviewH1">WHAT WE THINK</h1>
                                                                    <!-- CONTENT ELEMENT, uid:13252/text [begin] -->

                                                                    <template v-for="review in own_reviews">
                                                                        <div id="c13252" class="csc-default">
                                                                            <!-- Text: [begin] -->
                                                                            <h3 class="align-left"><span style="font-style: italic;">{{review.review}}</span></h3>
                                                                            <h6 class="align-left">{{review.author}}</h6>
                                                                            <!-- Text: [end] -->
                                                                        </div>

                                                                        <div class="csc-default greyDivider">
                                                                            <!-- Div element [begin] -->
                                                                            <div class="divider">
                                                                                <hr>
                                                                            </div>
                                                                            <!-- Div element [end] -->
                                                                        </div>
                                                                    </template>

                                                                    <!-- CONTENT ELEMENT, uid:13252/text [end] -->
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- CONTENT ELEMENT, uid:13253/gridelements_pi1 [end] -->
                                                    </div>
                                                </template>

                                                <template v-if="active_tab == 'location'">
                                                    <div class="tx-dftabs-tabContent" id="tx-dftabs-tabContent4">
                                                        <locations-map type="roadmap" height="400" v-if="resort" :zoom="13" :locations="locations" :center="center"></locations-map>

                                                        <!-- CONTENT ELEMENT, uid:31937/list [begin] -->
                                                        <!-- CONTENT ELEMENT, uid:31937/list [end] --> <!-- CONTENT ELEMENT, uid:3255/list [begin] -->
                                                        <div id="c3255" class="csc-default">
                                                            <!-- Plugin inserted: [begin] -->
                                                            <div class="tx_sbchalets">
                                                                <div class="row chaletLocationTable">
                                                                    <div class="col-sm-4">
                                                                        <h6> <b>Chalet Location</b> </h6>
                                                                        <p>
                                                                            <template v-for="location in chalet.$attributes.chalet_locations">
                                                                                 <span class="chaletLocationCol1label">
                                                                                    <b>{{location.name}}</b>
                                                                                </span> {{location.description}} <br>
                                                                            </template>
                                                                        </p>
                                                                    </div>
                                                                    <div class="col-sm-4">
                                                                        <h6> <b>Nearest Airports</b> </h6>
                                                                        <p>
                                                                            <template v-for="location in chalet.$attributes.airport_locations">
                                                                                 <span class="chaletLocationCol1label">
                                                                                    <b>{{location.name.replace(": ;", ":")}}</b>
                                                                                </span> {{location.description}} <br>
                                                                            </template>
                                                                        </p>
                                                                    </div>
                                                                    <div class="col-sm-4">
                                                                        <h6> <b>Map key</b> </h6>
                                                                        <div class="mapLegendDiv"> <span class="chaletLocationCol3label"> <img :src="$root.config.url+'/frontend/images/legendpropertyicon.png'" width="13px"> </span> Property location </div>
                                                                        <div class="mapLegendDiv"> <span class="chaletLocationCol3label"> <img :src="$root.config.url+'/frontend/images/legendskilifticon.png'" width="13px"> </span> Ski lift </div>
                                                                        <span class="clickmapicons">Click on icons for further info</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-if="active_tab == 'gastronome'">
                                                    <div class="tx-dftabs-tabContent" id="tx-dftabs-tabContent5" v-html="gastronome">
                                                    </div>
                                                </template>

                                                <template v-if="active_tab == 'availability'">
                                                    <slot @selected="selected"></slot>
                                                </template>
                                            </div>

                                            <template v-if="related.length">
                                                <div id="c1371" class="csc-default"> <!-- Text: [begin] --> <h5><b>&nbsp;LIKE THIS CHALET?</b> WE RECOMMEND:</h5> <!-- Text: [end] --> </div>

                                                <div id="c8243" class="csc-default">
                                                    <!-- Plugin inserted: [begin] -->
                                                    <div class="tx_sbchalets">
                                                        <div class="related_chalets row">

                                                            <chalet-recommendation :related_chalet="related_chalet" v-for="related_chalet in related" v-bind:key="related_chalet.uuid"></chalet-recommendation>


                                                        </div>
                                                    </div>
                                                    <!-- Plugin inserted: [end] -->
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <!-- CONTENT ELEMENT, uid:1300/shortcut [begin] -->
                                    <div id="c1300" class="csc-default">
                                        <!-- Inclusion of other records (by reference): [begin] --> <!-- CONTENT ELEMENT, uid:441/shortcut [begin] -->
                                        <div class="stickyChaletBlock">
                                            <!-- Inclusion of other records (by reference): [begin] --> <!-- CONTENT ELEMENT, uid:8365/list [begin] -->
                                            <chalet-enquiry-form :chalet="chalet"></chalet-enquiry-form>
                                            <!-- CONTENT ELEMENT, uid:8365/list [end] --> <!-- CONTENT ELEMENT, uid:8364/gridelements_pi1 [begin] -->
                                            <div id="c8364" class="csc-default">
                                                <div class="row">
                                                    <div class="col-md-12 chaletRightAvailability "> <a onclick="active_tab = 'availability'">View Availability</a> </div>

                                                    <div class="col-md-12 chaletRightCatalogue " v-if="chalet.$attributes.catalogue"> <a :href="chalet.$attributes.catalogue">Download Catalogue</a> </div>

                                                    <div class="col-md-12 sendChaletToFriends visible-lg-12"> <a href="mailto:?subject=Chalet Shalimar%20-%20A%20great%20option%20for%20our%20ski%20trip&amp;body=Hi,%20I’ve%20been%20having%20a%20good%20look%20around%20the%20web%20and%20I’ve%20found%20this%20chalet:%0D%0A%0D%0AChalet Shalimar%20-%20https%3A%2F%2Fwww.ski-boutique.co.uk%2Fresorts%2Fswitzerland%2Fzermatt%2Fchalet-shalimar%2F">SEND THIS CHALET TO FRIENDS</a> </div>
                                                    <div class="col-md-12 boutiqueBenefits visible-lg-12">
                                                        <h5>Boutique Benefits</h5>
                                                        <ul>
                                                            <li>LOCAL EXPERTISE</li>
                                                            <li>GUEST SUPPORT </li>
                                                            <li>BEST RATES</li>
                                                            <li>FRIENDLY ADVICE</li>
                                                            <li>SKIBOUTIQUE PA</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- CONTENT ELEMENT, uid:8364/gridelements_pi1 [end] --> <!-- CONTENT ELEMENT, uid:20483/image [begin] -->
                                            <div id="c20483" class="csc-default">
                                                <!-- Image block: [begin] -->
                                                <div class="csc-textpic csc-textpic-center csc-textpic-above">
                                                    <div class="csc-textpic-imagewrap" data-csc-images="1" data-csc-cols="2">
                                                        <div class="csc-textpic-center-outer">
                                                            <div class="csc-textpic-center-inner">
                                                                <figure class="csc-textpic-image csc-textpic-last"><img :src="$root.config.url+'/frontend/images/PricePromise_Chalet.jpg'" width="270" height="344" alt=""></figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- CONTENT ELEMENT, uid:1300/shortcut [end] --> <!-- CONTENT ELEMENT, uid:1301/shortcut [begin] -->
                                    <div id="c1301" class="csc-default">
                                        <!-- Inclusion of other records (by reference): [begin] --> <!-- Inclusion of other records (by reference): [end] -->
                                    </div>
                                    <!-- CONTENT ELEMENT, uid:1301/shortcut [end] -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Chalet from "../../src/Chalet";
    import Feature from "../../src/Feature";
    import Resort from "../../src/Resort";
    import Tabs from './components/Tabs';
    import LocationsMap from "./LocationsMap";
    import ChaletEnquiryForm from "./ChaletEnquiryForm";
    import Operator from "../../src/Operator";
    import ChaletRecommendation from "./ChaletRecommendation";
    import OperatorContent from "../../src/OperatorContent";
    import {FilterOperator} from "@tailflow/laravel-orion/lib/drivers/default/enums/filterOperator";
    import ChaletEnquiryModal from "./ChaletEnquiryModal";

    export default {
        props: {
            uuid: {
                type: String
            },
            costs: {
                type: Object
            }
        },
        data: function ()
        {
            return {
                chalet: new Chalet,
                resort: new Resort,
                loaded: false,
                active_tab: 'overview',
                features: [],
                operator: null,
                related: [],

                gastronome: null,

                format: new Intl.NumberFormat('en-US')
            }
        },
        mounted: function ()
        {
            Chalet.$query().find(this.uuid).then((chalet) =>
            {
                this.chalet = chalet;

                this.loaded = true;

                if (window.location.hash == '#details')
                {
                    this.active_tab = 'details';
                }

                Resort.$query().find(chalet.$attributes.resort_id).then((resort) =>
                {
                    this.resort = resort;
                });

                if (chalet.$attributes.operator_id)
                {
                    Operator.$query().find(chalet.$attributes.operator_id).then((operator) =>
                    {
                        this.operator = operator;
                    });

                    OperatorContent.$query()
                        .filter('operator_id', FilterOperator.Equal, chalet.$attributes.operator_id)
                        .filter('content_type', FilterOperator.Equal, 'gastronome')
                        .search()
                        .then((gastronome) =>
                    {
                        if (gastronome.length)
                        {
                            this.gastronome = gastronome[0].$attributes.content;
                        }
                    });
                }

                for (let i of chalet.$attributes.related_chalets)
                {
                    Chalet.$query().find(i).then((chalet) =>
                    {
                        this.related.push(chalet);
                    });
                }
            });

            Feature.$query().get().then((features) =>
            {
                this.features = features;
            });
        },
        computed: {
            features_steps: function ()
            {
                const result = []
                for (let i = 0; i < this.chalet.$attributes.features.length; i += 3)
                    result.push(this.chalet.$attributes.features.slice(i, i + 3))

                console.error(result);

                return result
            },

            tabs: function ()
            {
                let tabs = [
                    {
                        value: 'overview',
                        label: 'Overview'
                    },
                    {
                        value: 'details',
                        label: 'Details'
                    },
                    {
                        value: 'floorplans',
                        label: 'Floorplans'
                    },
                    {
                        value: 'reviews',
                        label: 'Reviews'
                    }
                ];

                if (this.resort)
                {
                    tabs.push(             {
                        value: 'location',
                        label: 'Location'
                    });
                }

                if (this.chalet.$attributes.type != "2" && this.chalet.$attributes.type != "8" && this.gastronome)
                {
                    tabs.push(   {
                        value: 'gastronome',
                        label: 'Gastronome'
                    })
                }

                tabs.push({
                    value: 'availability',
                    label: 'Availability'
                });

                return tabs;
            },

            luxe_services: function ()
            {
                let options = [];

                if (this.chalet)
                {
                    for (let features of this.chalet.$attributes.features)
                    {
                        if (features.name == 'Luxe Services')
                        {
                            options = options.concat(features.values.map((value) =>
                            {
                                let feature = this.features.find((feature) =>
                                {
                                    return feature.$attributes.name == value;
                                });

                                if (feature)
                                {
                                    return feature.$attributes.id;
                                }

                                return 0;
                            }));
                        }
                    }
                }

                if (this.operator)
                {
                    //options = options.concat(this.operator.$attributes.luxe_services);
                }

                return options.filter((value) => {return value > 0});
            },

            catering: function ()
            {
                let options = [];

                if (this.chalet)
                {
                    for (let features of this.chalet.$attributes.features)
                    {
                        if (features.name == 'Catering')
                        {
                            options = options.concat(features.values.map((value) =>
                            {
                                let feature = this.features.find((feature) =>
                                {
                                    return feature.$attributes.name == value;
                                });

                                if (!feature)
                                {
                                    feature = this.features.reverse().find((feature) =>
                                    {
                                        return value.indexOf(feature.$attributes.name) === 0;
                                    });
                                }

                                if (feature)
                                {
                                    return feature.$attributes.id;
                                }

                                return 0;
                            }));
                        }
                    }
                }

                if (this.operator)
                {
                    //options = options.concat(this.operator.$attributes.catering);
                }

                return options.filter((value) => {return value > 0});
            },

            locations: function ()
            {
                if (1==2)
                {
                    let location = this.resort.$attributes.locations.find((test_location) =>
                    {
                        return test_location.position == this.center;
                    });

                    if (!location)
                    {
                        location = this.resort.$attributes.locations.find((test_location) =>
                        {
                            return test_location.name == this.chalet.$attributes.name;
                        });
                    }

                    if (!location)
                    {
                        location = this.resort.$attributes.locations.find((test_location) =>
                        {
                            return test_location.name.indexOf(this.chalet.$attributes.name) !== false;
                        });
                    }
                }

                let locations = this.resort.$attributes.locations.filter((location) =>
                {
                    return location.type != 'chalet';
                });

                locations = locations.concat(this.chalet.$attributes.locations);

                let description = this.chalet.$attributes.pin_description;

                locations.push({
                    position: this.center,
                    name: this.chalet.$attributes.name,
                    description: description,
                    icon: this.$root.config.url+'/frontend/images/pin-chalet.png'
                });

                return locations;
            },

            center: function ()
            {
                if (!this.chalet.$attributes.location.lat && this.resort)
                {
                    return {
                        lat: parseFloat(this.resort.$attributes.location.lat),
                        lng: parseFloat(this.resort.$attributes.location.lng),
                    }
                }

                return {
                    lat: parseFloat(this.chalet.$attributes.location.lat),
                    lng: parseFloat(this.chalet.$attributes.location.lng),
                }
            },

            press_reviews: function ()
            {
                return this.chalet.$attributes.reviews.filter((review) =>
                {
                    return review.type == 'press';
                });
            },

            own_reviews: function ()
            {
                return this.chalet.$attributes.reviews.filter((review) =>
                {
                    return review.type == 'own';
                });
            }
        },
        methods: {
            selected: function ()
            {
                alert('x');
            }
        },
        components: {
            'tabs': Tabs,
            'locations-map': LocationsMap,
            'chalet-enquiry-form': ChaletEnquiryForm,
            'chalet-recommendation': ChaletRecommendation,
            'chalet-enquiry-modal': ChaletEnquiryModal
        }
    }
</script>

<style type="text/css">
    ul.tabs { list-style-type: none; padding-left: 0; } ul.tabs li { float: left; } input[type="checkbox"]{display: inline-block !important;}

    .tx-dftabs-tabContent {
        padding: 39px 0 0 !important;
    }
</style>
