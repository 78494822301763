<script>
    import Vue from 'vue'

    import {Orion} from "@tailflow/laravel-orion/lib/orion";

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    import 'vue2-datepicker/locale/uk';

    Orion.init(config.url, 'sapi');
    Orion.setToken('access-token-here');

    import * as VueGoogleMaps from 'vue2-google-maps'
    import LocationsMap from "./LocationsMap";

    import ResortGuide from "./ResortGuide";
    import ResortLocations from "./ResortLocations";

    import Tabs from "./components/Tabs";
    import Chalet from "./Chalet";
    import ChaletEnquiryModal from "./ChaletEnquiryModal";
    import Money from './Money.vue';
    import CurrencySelect from './CurrencySelect.vue';

    Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAPPGZt0Nx_u3xCTR-O8SDwTfdee2XTWg4', // @todo
        },
    });

    let app = new Vue({
        el: '#page',
        components: {
            'chalet': Chalet,
            'locations-map': LocationsMap,
            'tabs': Tabs,
            'resort-guide': ResortGuide,
            'resort-locations': ResortLocations,
            'chalet-enquiry-modal': ChaletEnquiryModal,
            'money': Money,
            'currency-select': CurrencySelect,
            DatePicker
        },
        methods: {
            show_booking_modal: function ()
            {
                this.showing_chalet_enquiry_modal = true;
            }
        },
        data: function ()
        {
            return {
                config: config,
                showing_chalet_enquiry_modal: false,

                currency: 'USD'
            };
        }
    });

    export default app;
</script>

<style lang="less">
    .vdpHeader {
        margin: 0;
    }

    .vdpComponent.vdpWithInput {
        width: 100%;
        display: block;
    }

    #flexslider-18 img {
        margin-top: unset !important;
    }

    .contact-social-media {
        img {
            margin-top: 0;
            position: absolute;
        }
    }
</style>