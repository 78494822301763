<template>
    <div class="chalet-locations-map">
        <GmapMap
                :center="center"
                :zoom="parseInt(zoom)"
                :map-type-id="type"
                style="width: 100%; height: 700px"
        >
            <template v-for="(location, index) in map_locations">
                <GmapMarker
                        :key="index"
                        :position="location.position"
                        :clickable="true"
                        :draggable="false"
                        @click="marker_click(location)"
                        :icon="location.icon"
                />
            </template>

            <gmap-info-window
                    v-if="infowindow"
                    :options="infowindow_options"
                    :position="infowindow.position"
                    :opened="infowindow != null" @closeclick="infowindow=null">
            </gmap-info-window>
        </GmapMap>
    </div>
</template>

<script>
    import Chalet from "../../src/Chalet";

    export default {
        props: {
            locations: {
                type: Array
            },
            center: {},
            zoom: {
            },
            type: {},
            height: {}
        },
        defaultProps: {
            zoom: 13,
            type: 'terrain',
            height: 700
        },
        data: function ()
        {
            return {
                chalet: {},
                infowindow: null
            }
        },
        computed: {
            map_locations: function ()
            {

                let locations = this.locations.map((location) =>
                {
                    if (location.type == 'chalet')
                    {
                        location.icon = this.$root.config.url+'/frontend/images/pin-chalet.png';
                    }

                    if (location.type == 'lift')
                    {
                        location.icon = this.$root.config.url+'/frontend/images/pin.png';
                    }

                    return location;
                });

                console.log('LOCATIONS=');
                console.log(locations);

                /**
                locations.push({
                    position: this.chalet.$attributes.location,
                    name: this.chalet.$attributes.name,
                    icon: this.$root.config.url+'/frontend/images/legendpropertyicon.png'
                });
                 **/

                return locations;
            },
            infowindow_options: function ()
            {
                let content =  '<strong>'+this.infowindow.name+'</strong>';

                if (this.infowindow.description)
                {
                    content += '<br />'+this.infowindow.description
                }

                return {
                    content: content,
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                }
            },
        },
        methods: {
            marker_click: function (location)
            {
                this.infowindow = location;
            }
        }
    }
</script>