var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relatedChalet col-md-3 col-xs-6 col-sm-4" },
    [
      _c("a", { attrs: { href: _vm.related_chalet.$attributes.url } }, [
        _c("img", {
          staticClass: "img-responsive",
          staticStyle: { height: "150px", "object-fit": "cover" },
          attrs: {
            src: _vm.related_chalet.$attributes.gallery[0],
            width: "278",
            height: "186",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "infoBar" },
          [
            _c("span", [_vm._v(_vm._s(_vm.related_chalet.$attributes.name))]),
            _vm._v(" "),
            _vm.resort
              ? [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.resort.$attributes.name) +
                      "\n            "
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }